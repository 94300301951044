import { useParams } from '@solidjs/router';
import { Component, createSignal, For, onMount, Show, Switch, Match } from 'solid-js';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Radio } from '~/components/common/buttons/Radio';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { StepFooter } from '~/pages/rental-application/steps/StepFooter';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { QuestionAndAnswers, QuestionType } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { GoToPrevStepUseCase } from '~/use-cases/rental-applications/goToPrevStepUseCase';

export const QuestionsStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const [isGoingToNextStep, setIsGoingToNextStep] = createSignal(false);

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: goToPrevStep } = useUseCase(GoToPrevStepUseCase);
  const { execute: goToNextStep, didSucceed: didSucceedGoingNext } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
  });

  const updateAnswer = (index: number, value: any) => {
    const questions = presentableRentalApplication()?.questionsAndAnswers ?? [];
    const updatedQuestions: QuestionAndAnswers[] = questions.map((question, i) => {
      return {
        question: question.question,
        answers: i === index ? [value] : question.answers,
      };
    });
    updateRentalApplication({ questions: updatedQuestions });
  };

  const onPrev = async () => {
    await goToPrevStep({
      currentStep: RentalApplicationSteps.QUESTIONS,
      credentials: { id: params.id, password: params.password },
    });
  };

  const onNext = async () => {
    await validateRentalApplication();

    if (!didSucceedValidation() || presentableRentalApplication()?.hasRentalApplicationInfoError) {
      return;
    }

    setIsGoingToNextStep(true);

    await goToNextStep({
      currentStep: RentalApplicationSteps.QUESTIONS,
      credentials: { id: params.id, password: params.password },
    });

    if (!didSucceedGoingNext()) {
      setIsGoingToNextStep(false);
    }
  };

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="flex h-section3 flex-col bg-white p-6">
        <ol class="ordered-list flex flex-col gap-3 rounded-2xl text-black">
          <For each={presentableRentalApplication()?.questionsAndAnswers}>
            {(question, index) => (
              <li class="flex h-9 items-center justify-between gap-2">
                <span>{t(question.question)}</span>
                <Switch
                  fallback={
                    <Radio
                      items={question.options}
                      value={question.answers.length > 0 ? question.answers[0] : undefined}
                      onInput={(value) => updateAnswer(index(), value)}
                    />
                  }>
                  <Match when={question.type === QuestionType.Input}>
                    <LabeledTextInput
                      id="rental-question-input"
                      value={question.answers.length > 0 ? question.answers[0] : undefined}
                      onInput={(value) => updateAnswer(index(), value)}
                    />
                  </Match>
                </Switch>
              </li>
            )}
          </For>
        </ol>
      </div>
      <StepFooter onNext={onNext} onPrevious={onPrev} />
    </Show>
  );
};
